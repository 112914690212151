export const mainRoute = '/home-page'
export const dashboardsRoute = '/dashboards'
export const agentsRoute = '/law-firms'
export const applicantsRoute = '/applicants'
export const attorneysRoute = '/attorneys'
export const advancedSearchRoute = '/standard-search'
export const smartSearchRoute = '/smart-search'
export const filingCheckRoute = '/filing-check'
export const conflictCheckRoute = '/conflict-check'
export const instructionCheckRoute = '/instruction-check'
export const whitespotCheckRoute = '/whitespot-check'
export const whitespotAgentsCheckRoute = '/whitespot-check/law-firms'
export const whitespotApplicantsCheckRoute = '/whitespot-check/applicants'
export const homepageRoute = '/home-page'
export const settingsRoute = '/settings'
export const userSettingsRoute = '/settings/user'
export const adminSettingsRoute = '/settings/admin'
export const billingSettingsRoute = '/settings/billing'
export const logoutRoute = '/logout'
export const searchFilingsRoute = '/standard-search/filings'
export const searchValidationsRoute = '/standard-search/validations'
export const resourcesRoute = '/resources'
export const dataCoverageRoute = '/resources/data-coverage'
export const buildYourOwnChartsRoute = '/custom-charts'
export const mutualityRoute = '/mutuality'
export const similarityRoute = '/similarity'
export const similarityAgentsRoute = '/similarity/law-firms'
export const similarityApplicantsRoute = '/similarity/applicants'
export const techOverlapRoute = '/tech-overlap'
export const techOverlapAgentsCheckRoute = '/tech-overlap/law-firms'
export const techOverlapApplicantsCheckRoute = '/tech-overlap/applicants'
export const comparisonRoute = '/comparison'
export const comparisonAgentsRoute = '/comparison/law-firms'
export const comparisonApplicantsRoute = '/comparison/applicants'
