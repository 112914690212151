export enum EntityType {
  Agent = 'agent',
  Applicant = 'applicant',
  Attorney = 'attorney',
}

export interface EntitySearchResult {
  alternativeName?: string
  applnCount: number
  country: string
  data?: {
    domain: string | null
    logoUrl: string | null
  }[]
  id?: number
  isApplicant?: boolean
  key: string
  name: string
  profile: boolean
  rep_inhouse?: boolean
  tmCount: number
  validationCount?: number
  value: string
}
