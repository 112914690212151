import { css, cx } from '@emotion/css'
import { CountryDataInterface, countryData } from '@pp/common/constants/country-data.const'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { Popup } from 'semantic-ui-react'

export const countryMap = [
  { abb: 'af', name: 'afghanistan' },
  { abb: 'al', name: 'albania' },
  { abb: 'dz', name: 'algeria' },
  { abb: 'as', name: 'americansamoa' },
  { abb: 'ad', name: 'andorra' },
  { abb: 'ao', name: 'angola' },
  { abb: 'ai', name: 'anguilla' },
  { abb: 'ag', name: 'antigua' },
  { abb: 'ar', name: 'argentina' },
  { abb: 'am', name: 'armenia' },
  { abb: 'aw', name: 'aruba' },
  { abb: 'au', name: 'australia' },
  { abb: 'at', name: 'austria' },
  { abb: 'az', name: 'azerbaijan' },
  { abb: 'bs', name: 'bahamas' },
  { abb: 'bh', name: 'bahrain' },
  { abb: 'bd', name: 'bangladesh' },
  { abb: 'bb', name: 'barbados' },
  { abb: 'by', name: 'belarus' },
  { abb: 'be', name: 'belgium' },
  { abb: 'bz', name: 'belize' },
  { abb: 'bj', name: 'benin' },
  { abb: 'bm', name: 'bermuda' },
  { abb: 'bt', name: 'bhutan' },
  { abb: 'bo', name: 'bolivia' },
  { abb: 'ba', name: 'bosniaandherzegovina' },
  { abb: 'bw', name: 'botswana' },
  { abb: 'bv', name: 'bouvetisland' },
  { abb: 'br', name: 'brazil' },
  { abb: 'vg', name: 'britishvirginislands' },
  { abb: 'bn', name: 'brunei' },
  { abb: 'bg', name: 'bulgaria' },
  { abb: 'bf', name: 'burkinafaso' },
  { abb: 'mm', name: 'burma' },
  { abb: 'bi', name: 'burundi' },
  { abb: 'tc', name: 'caicosislands' },
  { abb: 'kh', name: 'cambodia' },
  { abb: 'cm', name: 'cameroon' },
  { abb: 'ca', name: 'canada' },
  { abb: 'cv', name: 'capeverde' },
  { abb: 'ky', name: 'caymanislands' },
  { abb: 'cf', name: 'centralafricanrepublic' },
  { abb: 'td', name: 'chad' },
  { abb: 'cl', name: 'chile' },
  { abb: 'cn', name: 'china' },
  { abb: 'cx', name: 'christmasisland' },
  { abb: 'cc', name: 'cocosislands' },
  { abb: 'co', name: 'colombia' },
  { abb: 'km', name: 'comoros' },
  { abb: 'cg', name: 'congo' },
  { abb: 'ck', name: 'cookislands' },
  { abb: 'cr', name: 'costarica' },
  { abb: 'ci', name: 'ivorycoast' },
  { abb: 'hr', name: 'croatia' },
  { abb: 'cu', name: 'cuba' },
  { abb: 'cy', name: 'cyprus' },
  { abb: 'cz', name: 'czechrepublic' },
  { abb: 'dk', name: 'denmark' },
  { abb: 'dj', name: 'djibouti' },
  { abb: 'dm', name: 'dominica' },
  { abb: 'do', name: 'dominicanrepublic' },
  { abb: 'ec', name: 'ecuador' },
  { abb: 'eg', name: 'egypt' },
  { abb: 'sv', name: 'elsalvador' },
  { abb: 'gb', name: 'unitedkingdom' },
  { abb: 'uk', name: 'unitedkingdom' },
  { abb: 'gq', name: 'equatorialguinea' },
  { abb: 'er', name: 'eritrea' },
  { abb: 'ee', name: 'estonia' },
  { abb: 'et', name: 'ethiopia' },
  { abb: 'eu', name: 'europeanunion' },
  { abb: 'fk', name: 'falklandislands' },
  { abb: 'fo', name: 'faroeislands' },
  { abb: 'fj', name: 'fiji' },
  { abb: 'fi', name: 'finland' },
  { abb: 'fr', name: 'france' },
  { abb: 'gf', name: 'frenchguiana' },
  { abb: 'pf', name: 'frenchpolynesia' },
  { abb: 'tf', name: 'frenchterritories' },
  { abb: 'ga', name: 'gabon' },
  { abb: 'gm', name: 'gambia' },
  { abb: 'ge', name: 'georgia' },
  { abb: 'de', name: 'germany' },
  { abb: 'gh', name: 'ghana' },
  { abb: 'gi', name: 'gibraltar' },
  { abb: 'gr', name: 'greece' },
  { abb: 'gl', name: 'greenland' },
  { abb: 'gd', name: 'grenada' },
  { abb: 'gp', name: 'guadeloupe' },
  { abb: 'gu', name: 'guam' },
  { abb: 'gt', name: 'guatemala' },
  { abb: 'gw', name: 'guineabissau' },
  { abb: 'gn', name: 'guinea' },
  { abb: 'gy', name: 'guyana' },
  { abb: 'ht', name: 'haiti' },
  { abb: 'hm', name: 'heardisland' },
  { abb: 'hn', name: 'honduras' },
  { abb: 'hk', name: 'hongkong' },
  { abb: 'hu', name: 'hungary' },
  { abb: 'is', name: 'iceland' },
  { abb: 'in', name: 'india' },
  { abb: 'io', name: 'indianoceanterritory' },
  { abb: 'id', name: 'indonesia' },
  { abb: 'ir', name: 'iran' },
  { abb: 'iq', name: 'iraq' },
  { abb: 'ie', name: 'ireland' },
  { abb: 'il', name: 'israel' },
  { abb: 'it', name: 'italy' },
  { abb: 'jm', name: 'jamaica' },
  { abb: 'jp', name: 'japan' },
  { abb: 'jo', name: 'jordan' },
  { abb: 'kz', name: 'kazakhstan' },
  { abb: 'ke', name: 'kenya' },
  { abb: 'ki', name: 'kiribati' },
  { abb: 'xk', name: 'kosovo' },
  { abb: 'kw', name: 'kuwait' },
  { abb: 'kg', name: 'kyrgyzstan' },
  { abb: 'la', name: 'laos' },
  { abb: 'lv', name: 'latvia' },
  { abb: 'lb', name: 'lebanon' },
  { abb: 'ls', name: 'lesotho' },
  { abb: 'lr', name: 'liberia' },
  { abb: 'ly', name: 'libya' },
  { abb: 'li', name: 'liechtenstein' },
  { abb: 'lt', name: 'lithuania' },
  { abb: 'lu', name: 'luxembourg' },
  { abb: 'mo', name: 'macau' },
  { abb: 'mk', name: 'northmacedonia' },
  { abb: 'mg', name: 'madagascar' },
  { abb: 'mw', name: 'malawi' },
  { abb: 'my', name: 'malaysia' },
  { abb: 'mv', name: 'maldives' },
  { abb: 'ml', name: 'mali' },
  { abb: 'mt', name: 'malta' },
  { abb: 'mh', name: 'marshallislands' },
  { abb: 'mq', name: 'martinique' },
  { abb: 'mr', name: 'mauritania' },
  { abb: 'mu', name: 'mauritius' },
  { abb: 'yt', name: 'mayotte' },
  { abb: 'mx', name: 'mexico' },
  { abb: 'fm', name: 'micronesia' },
  { abb: 'md', name: 'moldova' },
  { abb: 'mc', name: 'monaco' },
  { abb: 'mn', name: 'mongolia' },
  { abb: 'me', name: 'montenegro' },
  { abb: 'ms', name: 'montserrat' },
  { abb: 'ma', name: 'morocco' },
  { abb: 'mz', name: 'mozambique' },
  { abb: 'na', name: 'namibia' },
  { abb: 'nr', name: 'nauru' },
  { abb: 'np', name: 'nepal' },
  { abb: 'an', name: 'netherlandsantilles' },
  { abb: 'nl', name: 'netherlands' },
  { abb: 'nc', name: 'newcaledonia' },
  { abb: 'pg', name: 'papuanewguinea' },
  { abb: 'nz', name: 'newzealand' },
  { abb: 'ni', name: 'nicaragua' },
  { abb: 'ne', name: 'niger' },
  { abb: 'ng', name: 'nigeria' },
  { abb: 'nu', name: 'niue' },
  { abb: 'nf', name: 'norfolkisland' },
  { abb: 'kp', name: 'northkorea' },
  { abb: 'mp', name: 'northernmarianaislands' },
  { abb: 'no', name: 'norway' },
  { abb: 'om', name: 'oman' },
  { abb: 'pk', name: 'pakistan' },
  { abb: 'pw', name: 'palau' },
  { abb: 'ps', name: 'palestine' },
  { abb: 'pa', name: 'panama' },
  { abb: 'py', name: 'paraguay' },
  { abb: 'pe', name: 'peru' },
  { abb: 'ph', name: 'philippines' },
  { abb: 'pn', name: 'pitcairnislands' },
  { abb: 'pl', name: 'poland' },
  { abb: 'pt', name: 'portugal' },
  { abb: 'pr', name: 'puertorico' },
  { abb: 'qa', name: 'qatar' },
  { abb: 're', name: 'reunion' },
  { abb: 'ro', name: 'romania' },
  { abb: 'ru', name: 'russia' },
  { abb: 'rw', name: 'rwanda' },
  { abb: 'sh', name: 'sainthelena' },
  { abb: 'kn', name: 'saintkittsandnevis' },
  { abb: 'lc', name: 'saintlucia' },
  { abb: 'pm', name: 'saintpierre' },
  { abb: 'vc', name: 'saintvincent' },
  { abb: 'ws', name: 'samoa' },
  { abb: 'sm', name: 'sanmarino' },
  { abb: 'gs', name: 'sandwichislands' },
  { abb: 'st', name: 'saotome' },
  { abb: 'sa', name: 'saudiarabia' },
  { abb: 'sn', name: 'senegal' },
  { abb: 'rs', name: 'serbia' },
  { abb: 'sc', name: 'seychelles' },
  { abb: 'sl', name: 'sierralone' },
  { abb: 'sg', name: 'singapore' },
  { abb: 'sk', name: 'slovakia' },
  { abb: 'si', name: 'slovenia' },
  { abb: 'sb', name: 'solomonislands' },
  { abb: 'so', name: 'somalia' },
  { abb: 'za', name: 'southafrica' },
  { abb: 'kr', name: 'southkorea' },
  { abb: 'es', name: 'spain' },
  { abb: 'lk', name: 'srilanka' },
  { abb: 'sd', name: 'sudan' },
  { abb: 'sr', name: 'suriname' },
  { abb: 'sj', name: 'svalbard' },
  { abb: 'sz', name: 'swaziland' },
  { abb: 'se', name: 'sweden' },
  { abb: 'ch', name: 'switzerland' },
  { abb: 'sy', name: 'syria' },
  { abb: 'tw', name: 'taiwan' },
  { abb: 'tj', name: 'tajikistan' },
  { abb: 'tz', name: 'tanzania' },
  { abb: 'th', name: 'thailand' },
  { abb: 'tl', name: 'timorleste' },
  { abb: 'tg', name: 'togo' },
  { abb: 'tk', name: 'tokelau' },
  { abb: 'to', name: 'tonga' },
  { abb: 'tt', name: 'trinidadandtobago' },
  { abb: 'tn', name: 'tunisia' },
  { abb: 'tr', name: 'turkey' },
  { abb: 'tm', name: 'turkmenistan' },
  { abb: 'tv', name: 'tuvalu' },
  { abb: 'ug', name: 'uganda' },
  { abb: 'ua', name: 'ukraine' },
  { abb: 'ae', name: 'unitedarabemirates' },
  { abb: 'us', name: 'unitedstates' },
  { abb: 'uy', name: 'uruguay' },
  { abb: 'um', name: 'usminorislands' },
  { abb: 'vi', name: 'usvirginislands' },
  { abb: 'uz', name: 'uzbekistan' },
  { abb: 'vu', name: 'vanuatu' },
  { abb: 'va', name: 'vaticancity' },
  { abb: 've', name: 'venezuela' },
  { abb: 'vn', name: 'vietnam' },
  { abb: 'wf', name: 'wallisandfutuna' },
  { abb: 'eh', name: 'westernsahara' },
  { abb: 'ye', name: 'yemen' },
  { abb: 'zm', name: 'zambia' },
  { abb: 'zw', name: 'zimbabwe' },
]

export interface FlagProps {
  big?: boolean
  className?: string
  country: string
}

const popupCss = css`
  ${increaseSpecificity(`
  padding: 0.5em 0.7em;
  fontSize: 0.9em;
`)}
`

const Flag = ({ country, className, big = false }: FlagProps) => {
  if (!country) {
    return null
  }

  const countryObject: CountryDataInterface | undefined = countryData.find(
    (item) => item.countryCode.toLowerCase() === country.toLowerCase(),
  )

  const getCountryName = () => countryMap.find((entry) => entry.abb === country.toLowerCase())?.name

  return (
    <Popup
      className={popupCss}
      basic
      content={countryObject && countryObject.country}
      trigger={
        <span
          className={cx(
            css`
              margin-right: 0px;
              width: ${big ? '35px' : '24px'};
              position: relative;
            `,
            `flag${big ? '-big' : ''} flag${big ? '-big' : ''}-${getCountryName()} ${className ? className : ''}`,
          )}
        />
      }
    />
  )
}

export default Flag
