import { NotificationStore } from '@pp/common/components/notification-center/notification.store'
import { FilingCheckStore } from '@pp/modules/check/filing/filing-check.store'
import { MutualityStore } from '@pp/modules/check/mutuality/mutuality.store'
import { SimilarityAgentsSearchStore } from '@pp/modules/check/similarity/tabs/similarity-agents-tab/similarity-agents-search.store'
import { SimilarityApplicantsSearchStore } from '@pp/modules/check/similarity/tabs/similarity-applicants-tab/similarity-applicants-search.store'
import { WhitespotAgentSearchStore } from '@pp/modules/check/whitespot/whitespot-agents-search.store'
import { WhitespotApplicantsSearchStore } from '@pp/modules/check/whitespot/whitespot-applicants-search.store'
import { FilingsSearchStore } from '@pp/modules/search/advanced-search/tabs/filings/filings-search.store'
import { ValidationsSearchStore } from '@pp/modules/search/advanced-search/tabs/validations/validations-search.store'
import { RouterStore } from 'mobx-react-router'

import { ApplicationStore } from './application.store'
import { FilterStore } from '../common/components/filters/filter.store'
import { ComparisonAgentsStore } from '../modules/check/comparison/tabs/agents/comparison-agents.store'
import { ComparisonApplicantsStore } from '../modules/check/comparison/tabs/applicants/comparison-applicants.store'
import { TechOverlapAgentsSearchStore } from '../modules/check/tech-overlap/tech-overlap-agents-search.store'
import { TechOverlapApplicantsSearchStore } from '../modules/check/tech-overlap/tech-overlap-applicants-search.store'

export interface RootStoreInterface {
  applicationStore: ApplicationStore
  comparisonAgentsStore: ComparisonAgentsStore
  comparisonApplicantsStore: ComparisonApplicantsStore
  filingCheckStore: FilingCheckStore
  filingsSearchStore: FilingsSearchStore
  filterStore: FilterStore
  mutualityStore: MutualityStore
  notificationStore: NotificationStore
  routerStore: RouterStore
  similarityAgentsSearchStore: SimilarityAgentsSearchStore
  similarityApplicantsSearchStore: SimilarityApplicantsSearchStore
  techOverlapAgentsSearchStore: TechOverlapAgentsSearchStore
  techOverlapApplicantsSearchStore: TechOverlapApplicantsSearchStore
  validationsSearchStore: ValidationsSearchStore
  whitespotAgentsSearchStore: WhitespotAgentSearchStore
  whitespotApplicantsSearchStore: WhitespotApplicantsSearchStore
}

class RootStore {
  applicationStore: ApplicationStore
  filterStore: FilterStore
  routerStore: RouterStore
  filingsSearchStore: FilingsSearchStore
  validationsSearchStore: ValidationsSearchStore
  notificationStore: NotificationStore
  whitespotAgentsSearchStore: WhitespotAgentSearchStore
  whitespotApplicantsSearchStore: WhitespotApplicantsSearchStore
  filingCheckStore: FilingCheckStore
  mutualityStore: MutualityStore
  similarityAgentsSearchStore: SimilarityAgentsSearchStore
  similarityApplicantsSearchStore: SimilarityApplicantsSearchStore
  techOverlapAgentsSearchStore: TechOverlapAgentsSearchStore
  techOverlapApplicantsSearchStore: TechOverlapApplicantsSearchStore
  comparisonAgentsStore: ComparisonAgentsStore
  comparisonApplicantsStore: ComparisonApplicantsStore

  constructor() {
    this.applicationStore = new ApplicationStore(this)
    this.routerStore = new RouterStore()
    this.filterStore = new FilterStore(this)
    this.filingsSearchStore = new FilingsSearchStore(this)
    this.validationsSearchStore = new ValidationsSearchStore(this)
    this.notificationStore = new NotificationStore(this)
    this.whitespotAgentsSearchStore = new WhitespotAgentSearchStore(this)
    this.whitespotApplicantsSearchStore = new WhitespotApplicantsSearchStore(this)
    this.filingCheckStore = new FilingCheckStore(this)
    this.mutualityStore = new MutualityStore(this)
    this.similarityAgentsSearchStore = new SimilarityAgentsSearchStore(this)
    this.similarityApplicantsSearchStore = new SimilarityApplicantsSearchStore(this)
    this.techOverlapAgentsSearchStore = new TechOverlapAgentsSearchStore(this)
    this.techOverlapApplicantsSearchStore = new TechOverlapApplicantsSearchStore(this)
    this.comparisonAgentsStore = new ComparisonAgentsStore(this)
    this.comparisonApplicantsStore = new ComparisonApplicantsStore(this)
  }
}

const rootStoreInstance = new RootStore()
export default rootStoreInstance
